import { createSlice } from '@reduxjs/toolkit';
import firebaseService from '@ameroservices-platform/shared/services/firebase';

const loppeDataSlice = createSlice({
	name: 'loppeData',
	initialState: {
		subscriptionTypes: [],
		subscriptionType: null,
		locations: {},
		locationsWithParent: {},
		pdfLinks: [],
		errorDialog: null,
		boothSummaries: {},
		locationProductGroup: {}
	},
	reducers: {
		setSubscriptionTypes(state, action) {
			state.subscriptionTypes = action.payload;
		},
		setSubscriptionType(state, action) {
			state.subscriptionType = action.payload;
		},
		setLocations(state, action) {
			state.locations = {
				...state.locations,
				...action.payload
			};
		},
		setLocationsWithParent(state, action) {
			state.locationsWithParent = {
				...state.locationsWithParent,
				[action.payload.id]: action.payload
			};
		},
		setPdfLinks(state, action) {
			state.pdfLinks = action.payload;
		},
		setErrorDialog(state, action) {
			state.errorDialog = action.payload;
		},
		closeDialog(state) {
			state.errorDialog = null;
		},
		setBoothSummary(state, action) {
			if (state.boothSummaries[action.payload.id]) {
				const months = action.payload.months || [];
				// merge the new data with the old data
				const monthsUpdated = [];
				state.boothSummaries[action.payload.id] = state.boothSummaries[action.payload.id].map(summary => {
					if (months.includes(summary.month)) {
						monthsUpdated.push(summary.month);
						return action.payload.summary.find(s => s.month === summary.month) || summary;
					}
					return summary;
				});
				// add new data
				const newSummaries = action.payload.summary.filter(s => !monthsUpdated.includes(s.month));
				state.boothSummaries[action.payload.id].push(...newSummaries);
			} else {
				state.boothSummaries[action.payload.id] = action.payload.summary;
			}
		},
		setBoothSummaries(state, action) {
			state.boothSummaries = {
				...state.boothSummaries,
				...action.payload
			};
		},
		setLocationsByProductGroup(state, action) {
			state.locationProductGroup = action.payload;
		}
	}
});

export const {
	setLocationsByProductGroup,
	setSubscriptionTypes,
	setSubscriptionType,
	setLocations,
	setLocationsWithParent,
	setPdfLinks,
	setErrorDialog,
	closeDialog,
	setBoothSummaries,
	setBoothSummary
} = loppeDataSlice.actions;

export default loppeDataSlice.reducer;

export const subscriptionTypesListener = () => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('subscriptionTypes')
		.where('deleted', '==', false)
		.onSnapshot(querySnapshot => {
			const subscriptionTypes = [];
			querySnapshot.forEach(doc => {
				const docData = doc.data();
				subscriptionTypes.push({
					...docData,
					id: doc.id
				});
			});
			dispatch(setSubscriptionTypes(subscriptionTypes));
		});
};

export const subscriptionTypeListener = subscriptionTypeUid => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('subscriptionTypes')
		.doc(subscriptionTypeUid)
		.onSnapshot(doc => {
			const subscription = { ...doc.data(), id: doc.id };
			dispatch(setSubscriptionType(subscription));
		});
};

export const getLocationsByProductGroups = productGroups => async dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	const locations = {};
	for (const productGroup of productGroups) {
		const locationDoc = await db.collection('locations').doc(productGroup.location).get();
		const location = { ...locationDoc.data(), id: locationDoc.id };
		locations[productGroup.id] = location?.name || '';
	}
	dispatch(setLocationsByProductGroup(locations));
};

export const subscriptionTypesBoothSummariesListener = (subscriptionTypeUids, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	const unsubFuncs = [];
	subscriptionTypeUids.forEach(uid => {
		unsubFuncs.push(
			db
				.collection('subscriptionTypes')
				.doc(uid)
				.collection('boothSummaryByMonth')
				.onSnapshot(querySnapshot => {
					const boothSummaries = [];
					querySnapshot.forEach(doc => {
						const docData = doc.data();
						boothSummaries.push({
							...docData,
							id: doc.id
						});
					});
					callback({ [uid]: boothSummaries });
				})
		);
	});

	return unsubFuncs;
};

export const subscriptionTypeBoothSummaryListener = subscriptionTypeUid => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('subscriptionTypes')
		.doc(subscriptionTypeUid)
		.collection('boothSummaryByMonth')
		.onSnapshot(querySnapshot => {
			const boothSummaries = [];
			querySnapshot.forEach(doc => {
				const docData = doc.data();
				boothSummaries.push({
					...docData,
					id: doc.id
				});
			});
			dispatch(setBoothSummary({ id: subscriptionTypeUid, summary: boothSummaries }));
		});
};

export const subscriptionTypeBoothSummaryMonthListener = (subscriptionTypeUid, months) => dispatch => {
	const chunk = _.chunk(months, 10);
	const db = firebaseService.getOrganisationRootDB();
	return chunk.map(chunkMonths =>
		db
			.collection('subscriptionTypes')
			.doc(subscriptionTypeUid)
			.collection('boothSummaryByMonth')
			.where('month', 'in', chunkMonths)
			.onSnapshot(querySnapshot => {
				const boothSummaries = [];
				querySnapshot.forEach(doc => {
					const docData = doc.data();
					boothSummaries.push({
						...docData,
						id: doc.id
					});
				});
				dispatch(setBoothSummary({ id: subscriptionTypeUid, summary: boothSummaries, months: chunkMonths }));
			})
	);
};

export const locationByUidListener = (uids, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	const unsubFuncs = [];
	uids.forEach(uid => {
		unsubFuncs.push(
			db
				.collection('locations')
				.doc(uid)
				.onSnapshot(doc => {
					const location = { ...doc.data(), id: doc.id };
					if (!location.deleted) {
						callback(location);
					}
				})
		);
	});
	return unsubFuncs;
};

export const locationByParentListener = (uids, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	const unsubFuncs = [];
	uids.forEach(uid => {
		unsubFuncs.push(
			db
				.collection('locations')
				.where('parent', '==', uid)
				.where('deleted', '==', false)
				.onSnapshot(querySnapshot => {
					const locations = {};
					querySnapshot.forEach(doc => {
						locations[doc.id] = { ...doc.data(), id: doc.id };
					});
					callback(locations);
				})
		);
	});
	return unsubFuncs;
};

export const getProduct = async productUid => {
	const db = firebaseService.getOrganisationRootDB();

	const doc = await db.collection('products').doc(productUid).get();
	return { ...doc.data(), id: doc.id };
};

export const updatePdfLinks = labels => dispatch => {
	Promise.all(
		labels.map(async label => {
			const url = await getPdfDownloadLink(label.pdfUrl);
			return { ...label, url };
		})
	).then(urls => {
		dispatch(setPdfLinks(urls.filter(({ url }) => !!url)));
	});
};

/**
 * @param {string} productUid - product uid
 * @param {Array<string>} barcodes - Array of barcodes as string
 * @returns {Promise<*>} - don't know what message is returned - feel free to adjust
 */
export const markBarcodesAsPrinted = async (productUid, barcodes) => {
	try {
		const resp = await firebaseService.callFunctionByName('organisationProductMarkProductLabels', {
			productUid,
			labels: barcodes
		});
		return resp.data;
	} catch (e) {
		throw new Error(e);
	}
};

export const markBarcodesAsPrintedOptimizedV2 = async productUidsAndBarcodes => {
	try {
		const resp = await firebaseService.callFunctionByName('organisationProductMarkProductLabels', {
			productUidsAndBarcodes
		});
		return resp.data;
	} catch (e) {
		throw new Error(e);
	}
};

/**
 * @param {string} productUid - product uid
 * @param {number} amount - number of labels to create
 * @returns {Promise<*>} - don't know what message is returned - feel free to adjust
 */
export const createLabels = async (productUid, amount) => {
	try {
		const resp = await firebaseService.callFunctionByName('organisationProductAddProductAmount', {
			productUid,
			amount
		});
		return resp.data;
	} catch (e) {
		throw new Error(e);
	}
};

/**
 * @param {string} pdfUrl
 * @returns {Promise<string>}
 */
export const getPdfDownloadLink = async pdfUrl => {
	const storage = firebaseService.getStorage();
	const pathReferenceForTicket = storage.ref(pdfUrl);

	try {
		return await pathReferenceForTicket.getDownloadURL();
	} catch (e) {
		throw new Error(e);
	}
};

/**
 * @param {string} productUid - product uid
 * @param {Array<string>} labels - array of labels to delete
 * @returns {Promise<*>}
 */
export const deleteLabelsOnProduct = async (productUid, labels) => {
	try {
		const resp = await firebaseService.callFunctionByName('organisationProductRemoveProductAmount', {
			productUid,
			labels
		});
		return resp.data;
	} catch (e) {
		throw new Error(e);
	}
};

/**
 * @param {string} productUid - product uid
 * @returns {Promise<*>}
 */
export const deleteProduct = async productUid => {
	try {
		const resp = await firebaseService.callFunctionByName('organisationProductDeleteProduct', {
			productUid
		});
		return resp.data;
	} catch (e) {
		throw new Error(e);
	}
};

export const pingHandler = async () => {
	await firebaseService.callFunctionByName('organisationProductProductHandlerPing');
};

export const selectSubscriptionTypes = state => state.shared.loppeData.subscriptionTypes;
export const selectSubscriptionType = state => state.shared.loppeData.subscriptionType;
export const selectLocations = state => state.shared.loppeData.locations;
export const selectLocationsWithParent = state => state.shared.loppeData.locationsWithParent;
export const selectPdfLinks = state => state.shared.loppeData.pdfLinks;
export const selectErrorDialog = state => state.shared.loppeData.errorDialog;
export const selectBoothSummaries = state => state.shared.loppeData.boothSummaries;
export const selectLocationProductGroup = state => state.shared.loppeData.locationProductGroup;
export const selectBoothSummaryById = (state, id) => state.shared.loppeData.boothSummaries[id];
